:root {
  --main-color: #686767;
  --second-color: #ecebeb;
}

/* core styles */
.mmc {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* app component style */
.app {
  padding: 0px;
  margin: 0px;
}

.h-85{
  height:85vh
}

/* Starting Countdown Styles  */

.count_content {
  flex-direction: column;
}

.sc_main_col {
  width: 100%;
  height: 100vh;
  background: #ffffff;
  flex-direction: column;
}

.sc_message {
  margin-bottom: 1rem;
}

.sc_message_text {
  width: 142px;
  height: 40px;
  left: 652px;
  top: 240px;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -0.006em;
  color: #000000;
}

.cr_message_text {
  width: 255px;
  height: 40px;
  left: 597px;
  top: 240px;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -0.006em;
  color: #000000;
}

/* result page */
.adv_text {
  width: 450px;
  height: 63px;
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.006em;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.sub_adv_text {
  width: 350px;
  height: 48px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #6d747a;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.result_progress {
  flex-direction: column;
}

.adv {
  flex-direction: column;
}

.share_score_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  width: 162px;
  height: 40px;
  background: #2f6ce5;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

/* button,
[type="button"],
[type="reset"],
[type="submit"] {
  background-color: none !important;
  background-image: none;
} */

.share_link {
  width: 130px;
  height: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: -0.01em;
  color: white;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.share_link:hover {
  color: white;
}

.back_home_link {
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  color: #2f6ce5;
}

.message_to_user_text {
  width: 350px;
  height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #6d747a;
}

.retake_btn_box {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  gap: 10px;
  width: 76px;
  height: 32px;
  left: 1284px;
  top: 24px;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 8px;
  margin-top: 1.5rem;
  margin-right: 5rem;
}

.retake_btn_text {
  width: 52px;
  height: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: -0.01em;
  color: #08090a;
  flex: none;
  margin-left: 0.1rem;
  order: 0;
  flex-grow: 0;
}

/* Quiz page styles */
.quiz a {
  text-decoration: underline !important;
}
.question_text_main {
  width: 100%;
  margin-top: 1rem !important;
  height: 100%;
  align-items: flex-start !important;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  display: flex;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.006em;
  color: #08090a;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.question_code_main {
  height: 100%;
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.001em;
  /* width: 50%; */
  overflow-wrap: break-word;
  color: #000000;
}

.quiz_col_main {
  flex-direction: column;
}

.skip_this_question_col {
  padding: 20px;
  padding-left: 25px;
}

.image_question_row {
  margin-top: 1rem;
}

.image_question_col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-left: 25px;
  padding-top: 0px;
  width: 498px;
  height: 330px;
}

.text_question_col {
  margin-top: 1rem !important;
}

.image_quest_real_img {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #ebedf0;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}

.ant-image {
  width: 498px !important;
  height: 330px !important;
  /* overflow: hidden; */
}

.ant-image-img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.code_question_row {
  margin-top: 2rem;
}

.code_answers {
  color: white;
  width: 582px;
  background-color: #474747;
  padding: 0px;
  border-radius: 8px;
}

.m_auto {
  margin: auto;
  margin-top: 32px;
}

.image_quest_real_img:hover {
  opacity: 0.5;
}

.code_text {
  box-sizing: border-box;
  height: 330px;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
  padding-top: 2rem;
  padding-left: 1.5rem;
}

.image_question_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center center;
  background-size: cover;
  height: 330px;
}

.skip_me {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2f6ce5;
}

.radio_mcq {
  width: 592px;
  height: 48px;
  left: 0px;
  top: 0px;
  background: #2f6ce5;
  opacity: 0.07;
  border-radius: 32px;
  display: flex;
}

::webkit-scrollbar {
  display: none !important;
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  /* -ms-overflow-style: none; */
  /* IE and Edge */
  /* scrollbar-width: none; */
  /* Firefox */
}

.ant-radio {
  margin-right: 1rem !important;
}

.code_question_box {
  background: #474747;
  border-radius: 8px;
  width: 582px;
  max-height: 592px;
  min-height: 20px;

  padding-top: 1rem;
  padding-left: 0.5rem;
  overflow-x: hidden;
}

.code_question_box:hover {
  background: #a3a3a3;
}

/* .code_text {
  height: 126px;
  width: 200px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.001em;
  color: #ffffff;
  padding-top: 2rem;
  padding-left: 1.5rem;
} */

.ant-statistic-content-value {
  width: 46px;
  height: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 0.7rem;
  margin-left: 0.55rem;
}

@media screen and (max-width: 558px) {
  .question-code {
    width: 100%;
  }

  .question_text_main {
    /* width: 500px !important; */
    height: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: 0.0015em;
    color: #08090a;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .code_question_box {
    width: 293px;
    height: 293px;
  }

  .code_answers {
    width: 293px;
  }
}

.code_col {
  margin-top: 1.5rem !important;
}

/* @media screen and (min-width: 1440px) {

  .justifyC {
    justify-content: space-between;
  }
} */

@media screen and (max-width: 1458px) {
  .question_text_main {
    /* width: 499px; */
    font-size: 20px;
  }
}

@media screen and (max-width: 630px) {
  .question-code,
  .question_text {
    width: 100%;
  }

  .mcq_btn {
    width: 320px !important;
    margin-top: 1rem !important;
    /* width: 592px; */
    height: max-content;
    background: #e8f0ff;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .ant-radio-wrapper {
    display: flex;
    flex-direction: column;
  }

  .ant-radio {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    position: relative;
    top: 2.2em !important;
    margin-left: 0.5rem !important;
    display: inline-block;
    outline: none;
    cursor: pointer;
  }

  .radio_mcq_text {
    padding-left: 2rem;
  }

  .radio_mcq_text {
    width: auto !important;
    height: 24px;
    margin-top: 0.8125rem;
    left: 52px;
    top: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #6d747a;
  }

  .ant-radio {
    margin-left: 0.3rem;
  }
}

@media screen and (max-width: 991px) {
  .question-code {
    width: 100%;
  }

  .skip_quest {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 381px) {
}

.question-code {
  width: 100%;
}

.code_style {
  height: 126px;
  left: 24px;
  right: 24px;
  top: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.001em;
  color: #ffffff;
}

@media screen and (max-width: 1370px) {
  .code_col {
    flex: 0 0 50% !important;
    max-width: 100% !important;
  }

  .code_ques_row {
    row-gap: 10px !important;
    width: 784px !important;
    display: flex !important;
    justify-content: center !important;
    margin: auto !important;
    margin-top: 2rem !important;
  }
}

@media screen and (max-width: 798px) {
  .question-code {
    width: 100%;
  }

  .code_ques_row {
    row-gap: 10px !important;
    width: auto !important;
    display: flex !important;
    justify-content: center !important;
    margin: auto !important;
    margin-top: 2rem !important;
  }
}

.question-Id {
  font-size: 20px;
  padding: 10px 20px 10px 30px;
  margin: 5px;
  background-color: #eeeeee;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.question-code {
  padding: 16px 0 24px 24px;
  /* margin: 0px 100px; */
  background-color: #eeeeee;
  border-radius: 8px;
  border: 1px solid #ced4da;
  /* min-height: 20px;
  max-height: 364px;
  width: 1212px; */
}

.question-Id:hover {
  background-color: #11cdef;
  color: white;
}

.left-side {
  width: 20%;
  z-index: 1;
}

.Admin-main-div {
  display: flex;
  justify-content: center;
}

.outlet {
  width: 100%;
}

@media (min-width: 1140px) and (max-width: 1400px) {
  .outlet {
    width: 80%;
  }
}
.select-box {
  width: fit-content !important;
}
.sideNavbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999999999 !important;
  height: 100vh;
  padding: 5px;
}

.options-text {
  font-size: 17px;
}

.sideLogo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* margin: 30px 0px; */
}

.sideLogo h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;
}

.sideNavbar p {
  color: rgb(0, 0, 0);
  padding-left: 13px;
  border-radius: 12px;
  margin: 5px 0;
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .sideNavbar ul {
    margin: 0px !important;
    padding: 0px !important;
  }
  .fa-layer-group {
    margin: 0 5px !important;
  }
}
.sideNavbar p:hover {
  color: white;
  background-color: var(--main-color);
  cursor: pointer;
}

/* .nav-link:hover {
  color: white;
  background-color: var(--main-color);
  cursor: pointer;
} */

.sideNavbar-active {
  color: white !important;
  background-color: var(--main-color);
  cursor: pointer;
}

/* Assignment part  */

.mous {
  cursor: pointer;
}

.assignment {
  background-color: white;
  color: black !important;
  width: 100%;
  padding: 20px 29px;
}

.ass-header {
  display: flex;
  justify-content: space-between;
}

.assignment h4 {
  font-size: 20px;
  font-weight: 600;
  font-weight: bold;
  margin-bottom: 0px;
}

.assignment-btn {
  font-weight: 600;
  font-size: 17px;
  padding: 4px 15px;
  border-radius: 10px;
  background-color: rgb(232, 232, 232);
}

.assignment-btn:hover {
  background-color: rgb(255, 255, 255);
  box-shadow: 4px 10px 10px rgb(0 0 0 / 0.05);
}

.assignment-info-div {
  max-width: 800px;
  background-color: white;
  border-radius: 10px;
  color: black;
}

.modal-delete-btn {
  padding: 6px 30px;
  margin: 5px;
  font-size: 17px;
  font-weight: 600;
  border-radius: 12px;
  box-shadow: 0px 0px 4px rgb(60, 60, 60);
}

.modal-btn {
  padding: 6px 30px;
  margin: 5px;
  font-size: 17px;
  font-weight: 600;
  border-radius: 12px;
  box-shadow: 0px 0px 4px rgb(60, 60, 60);
}

.modal-btn:hover {
  background-color: #abaaaa;
  color: white;
}

.modal-delete-btn:hover {
  background-color: rgb(255, 0, 0);
  color: rgb(255, 255, 255);
}

.needs-filed {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-auto-rows: auto;
  column-gap: 20px;
}

/* .needs-filed .desc {
  grid-column: 3 !important;
  grid-row: 2 / 4 !important;
} */
.needs-filed input {
  border: 1px solid rgb(255, 255, 255);
}

input {
  border: 1px solid #dee2e6;
}

/* Assignment adding  */
.assignment-adding-div {
  max-width: 95%;
  background-color: white;
  border-radius: 15px;
  color: black;
  padding: 20px 19px !important;
  margin: 10px auto;
}

.assignment-add-btn {
  padding: 10px 25px;
  border-radius: 12px;
  box-shadow: 1px 3px 7px rgb(179, 179, 179);
  cursor: pointer;
}

.head-btn {
  line-height: 0.5715 !important;
  font-size: 20px !important;
}

.assignment-add-red {
  padding: 6px 15px;
  border-radius: 12px;
  background-color: rgb(255, 106, 106);
  color: white;
  box-shadow: 1px 3px 7px rgb(179, 179, 179);
  cursor: pointer;
}

.assignment-add-blue {
  padding: 6px 15px;
  border-radius: 12px;
  background-color: #11cdef;
  color: white;
  box-shadow: 1px 3px 7px rgb(179, 179, 179);
  cursor: pointer;
}

/* question part  */

.question-info-div {
  max-width: 800px;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  color: black;
}

.question-options {
  display: grid;
  grid-template-columns: auto auto;
}

.ass-title {
  display: inline;
}

@media only screen and (max-width: 1380px) {
  .left-side {
    width: 295px;
  }
}

.disable_previous_btn,
.previous_btn {
  margin-top: 2rem;
  margin-left: 7rem;
}

@media only screen and (max-width: 800px) {
  .quiz_timer_box {
    margin-top: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .disable_previous_btn,
  .previous_btn {
    position: absolute !important;
    left: -97px !important;
    top: -13px !important;
  }
  a {
    display: inline-block !important;
  }
  .question_text_main {
    font-size: 14px;
  }
  .retake_btn_box {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px;
    gap: 10px;
    width: 76px;
    height: 32px;
    left: 1284px;
    top: 24px;
    background: #ffffff;
    border: 1px solid #ced4da;
    border-radius: 8px;
    margin-top: 0.5rem;
    margin-right: 0rem;
  }
}

@media only screen and (max-width: 1220px) {
  .left-side {
    width: 298px;
  }

  .assignment-btn {
    font-weight: 600;
    font-size: 15px;
    padding: 3px 12px;
    border-radius: 8px;
    background-color: #e8e8e8;
  }

  .ass-title {
    font-size: 20px;
  }
}

.main-sideNav {
  background-color: var(--second-color);
}

.bg-nav {
  background-color: var(--second-color);
}

/* @media only screen and (max-width: 769px) {
  .Admin-main-div {
    display: block;
  }

  .left-side {
    width: 100%;
  }

  .sideNavbar {
    position: static;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .sideLogo {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .sideLogo h3 {
    font-size: 22px;
    font-weight: 700;
    margin-left: 10px;
    margin-right: 30px;
  }

  .sideNavbar p {
    color: rgb(0, 0, 0);
    padding: 6px;
    border-radius: 12px;
    margin-left: 5px;
    margin-right: 5px;
  }
} */

@media only screen and (max-width: 890px) {
  .assignment {
    padding: 20px 30px;
  }

  .ass-header {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .sideNavbar h3 {
    font-size: 15px;
  }

  .sideNavbar p {
    color: rgb(0, 0, 0);
    padding: 5px 9px;
    border-radius: 12px;
    margin: 5px;
    font-size: 14px;
  }

  .needs-filed {
    grid-template-columns: auto;
  }
}

@media only screen and (max-width: 540px) {
  .assignment {
    padding: 10px 20px;
  }

  .assignment h4 {
    font-size: 15px;
  }

  .assignment-btn {
    font-size: 12px;
  }

  .question-options {
    grid-template-columns: auto;
  }

  .sideLogo h3 {
    font-size: 12px;
  }

  .ass-header {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 30px;
  }

  .question-info-div h5 {
    font-size: 17px;
  }

  .question-info-div h6 {
    font-size: 13px;
  }

  .sideNavbar h3 {
    font-size: 13px;
  }

  .sideNavbar p {
    color: rgb(0, 0, 0);
    padding: 5px 9px;
    border-radius: 12px;
    margin: 5px;
    font-size: 11px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .options-text {
    font-size: 13px;
  }

  .assignment-add-btn {
    padding: 7px 15px;
    font-size: 14px;
  }

  .assignment-add-red {
    padding: 5px 10px;
    font-size: 14px;
  }
}

.top_inp {
  /* border: none; */
  padding-left: 0.4rem;
}

.rmBtn {
  border-radius: 10% !important;
  border: none !important;
}

.addSub {
  background: #0dbe0d !important;
  color: white !important;
  margin-top: 1rem !important;
}

.option_inp {
  margin-left: 1rem !important;
}

/* .semicircle-container {
  position: absolute !important;
  top: 140px !important;
} */

.semicircle-percent-value {
  width: 100% !important;
  left: 0px !important;
  text-align: center !important;
  bottom: 0px !important;
  top: 75px !important;
  position: absolute !important;
  color: black !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 38px !important;
}
.semicircle-percent-value::after {
  content: "";
  position: absolute;
  width: 20%;
  height: 3px;
  bottom: 65%;
  left: 40%;
  background-color: #cddcfa;
}
.semicircle_total_questions {
  color: #939ca3 !important;
  font-weight: lighter;
  font-size: 25px;
  position: relative;
  top: -8px;
}

.adv {
  margin-top: -8rem;
}

@media (min-width: 590px) and (max-width: 1476px) {
  /* .semicircle-container {
    position: absolute !important;
    top: 68px !important;
  } */
  .adv {
    margin-top: -8rem;
  }
}
@media only screen and (max-width: 588px) {
  /* .semicircle-container {
    position: absolute !important;
    top: 68px !important;
  } */

  .adv {
    margin-top: -2rem;
  }
  .semicircle-percent-value::after {
    bottom: 65%;
  }
}
@media only screen and (max-width: 572px) {
  .semicircle-percent-value::after {
    bottom: 25%;
  }
}

@media screen and (max-width: 768px) {
  .question-code {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .question-code {
    width: 100%;
  }
}

@media screen and (max-width: 574px) {
  .question-code {
    width: 100%;
  }

  .adv_text {
    width: 250px;
    height: 63px;
    /* margin-top: 2rem !important; */
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 1rem;
  }

  .sub_adv_text {
    /* margin-top: 4rem !important; */
    width: 264px;
    height: 48px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: #6d747a;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .result_h2 {
    margin-top: 18rem !important;
  }

  .message_to_user_text {
    width: 203px;
    height: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: #6d747a;
    margin-bottom: 4rem;
  }
}

.previous_btn {
  width: 179px;
  height: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #1d44b0;
  opacity: 0.5;
  flex: none;
  order: 1;
  flex-grow: 0;
  position: absolute;
  left: -13px;
  top: 3px;
}
@media (min-width: 480px) {
  .container {
    max-width: 100%;
  }
}

.disable_previous_btn {
  width: 179px;
  height: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #374365;
  opacity: 0.5;
  flex: none;
  order: 1;
  flex-grow: 0;
  position: absolute;
  left: -13px;
  top: 3px;
}

.quiz_timer_box {
  margin-top: 1.5rem;
  margin-right: 4rem;
}

.prev_arrow {
  margin-right: 0.5rem !important;
}

.result_header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}

.quiz_main_container {
  height: 100vh;
  width: 100%;
}

.quiz_timer_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
  gap: 8px;
  width: 94px;
  height: 40px;
  left: 1271px;
  top: 24px;
  background: #00b879;
  border-radius: 8px;
}

.question_num_text {
  width: 112px;
  height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.006em;
  color: #2f6ce5;
  justify-content: center;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.by_100 {
  width: 48px;
  height: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.006em;
  color: #939ca3;
  flex: none;
  order: 1;
  flex-grow: 0;
  position: absolute;
  /* top: 181px; */
  /* left: 787px; */
}

.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  word-break: break-all;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
  padding: 0.2rem !important;
}

.quiz_timer_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quiz_timer_text {
  margin-right: 0.7rem !important;
}

.mcq_btn {
  width: 592px;
  height: max-content;
  background: #e8f0ff;
  border-radius: 32px;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; */
}

.radio_mcq_text {
  margin-top: 0.6rem;
  width: 490px;
  word-wrap: break-word;
  height: max-content;
  left: 52px;
  top: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #6d747a;
  text-align: left;
  padding-bottom: 0.4rem;
}

.makeMeBlue:hover {
  background: #2f6ce5;
}
.checkbox {
  transition: 0.5s;
}
.checkbox::after,
.quiz-prevchecked::after {
  content: "";
  position: absolute;
  width: 9px;
  height: 9px;
  top: 22.5px;
  left: 16.5px;
  z-index: 9999999;
  border-radius: 50%;
  background-color: #2f6ce5;
  display: none;
}
@media screen and (max-width: 630px) {
  .checkbox::after,
  .quiz-prevchecked::after {
    top: 54px;
    left: 14px;
  }
}
.checkbox:hover::after,
.quiz-prevchecked::after {
  display: block;
}
.and_me_white:hover {
  color: #ffffff;
}
.wrong-answer {
  background-color: #ffbec0 !important;
}
.color {
  color: #ffa74e;
}

.info-input {
  padding: 5px;
  width: 100%;
  border-radius: 8px !important;
}

.retake_disable {
  background: #e7e7e793;
}

.retake_dis_text {
  color: gray;
}

.true {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: green;
  display: inline-block;
}

.answer {
  width: 47px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.attempts .table button,
.attempts .table [type=""],
.attempts .table [type="reset"],
.attempts .table [type="submit"] {
  background-color: #6c757d !important;
  background-image: none;
  margin-top: 10px;
}

span.sr-only {
  display: none !important;
}

.table-responsive,
.react-bootstrap-table {
  max-height: 75vh;
  /*overflow-y: scroll;*/
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  table-layout: fixed;
}

.react-bootstrap-table table {
  table-layout: fixed;
  text-align: center;
}

thead {
  position: sticky;
  top: -1px;
  width: 100%;
  font-weight: bold !important;
  background-color: white !important;
}

thead tr th {
  background-color: var(--second-color) !important;
}

tbody td {
  /*vertical-align: middle;*/
}

/* tbody td {
  padding: 15px 5px !important;
} */
/*.pagination {
  margin-bottom: 0px !important;
}*/

.filter5{
  cursor: pointer;
}

.dropdown-toggle {
  background-color: white !important;
  border: #223968 !important;
}

/*  */
.dropdown-toggle::after {
  border: none !important;
}

.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown li {
  cursor: pointer;
}

.assess-backround {
  background-image: url(./assets/Rectangle\ 7.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 135px;
  height: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-responsive::-webkit-scrollbar {
  width: 8px;
}

.table-responsive::-webkit-scrollbar-track {
  background: #f8f9fa;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  border-radius: 20px;
  cursor: pointer !important;
}

.assignment-header {
  z-index: 2;
  font-size: 20px !important;
  padding: 0px 32px;
}

.atg-assignment {
  margin-bottom: 20px;
}

.update-page {
  position: sticky !important;
  top: 0px;
  z-index: 1;
}

.popup-image {
  width: 100px;
  height: 100px;
}

.test:not(:last-of-type) {
  border-bottom: 1px solid var(--second-color);
}

.ant-radio {
  padding-left: 10px !important;
}
.quiz-image {
  transition: 0.5s;
  border: 3px solid transparent;
}
.quiz-checked::after,
.wrongImage-answer::after {
  border: 4px solid #2f6ce5;
  content: "\f00c";
  color: #fff;
  font-family: "FontAwesome";
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #2f6ce5;
  justify-content: center;
  align-items: center;
  display: flex;
}
.wrongImage-answer::after {
  border: 4px solid #eb5366;
  width: 30px;
  height: 30px;
  content: "\f00d";
  background-color: #eb5366;
}
/* .quiz-checked::after {
  display: flex;
} */
/* .quiz-image:hover::after {
  display: flex;
} */
.quiz-image:hover,
.quiz-checked {
  border: 3px solid #2f6ce5 !important;
}
.wrongImage-answer {
  border: 3px solid #eb5366 !important;
}
.quiz-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  width: 100%;
  height: 100%;
  background-color: #00000070;
  border: none;
}
.quiz-code {
  background: #a3a3a3 !important;
}

.image-style{
  max-width: 600px;
}